import { ApiBuilderType } from 'modules/common/types';

export const setIsLogsEnabled = (builder: ApiBuilderType) => {
	return builder.mutation<void, boolean>({
		query: (isEnabled) => ({
			url: '/log-rocket-configuration/logs',
			method: 'POST',
			body: { isEnabled },
		}),
	});
};
