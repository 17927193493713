import { RoleEnum } from 'modules/auth/enums';
import { PathEnum, PageDefenseEnum } from 'modules/common/enums';
import { PageType } from 'modules/common/types';
import { CategoryTreePage } from './CategoryTreePage';
import { HomeContent } from './HomeContent';
import { UpcomingCategories } from './UpcomingCategories';

export const pages: PageType[] = [
	{
		path: PathEnum.CATEGORIES,
		component: CategoryTreePage,
		pageDefense: PageDefenseEnum.AUTH,
		rolesAllowed: [RoleEnum.ADMIN],
	},
	{
		path: PathEnum.UPCOMING_CATEGORIES,
		component: UpcomingCategories,
		pageDefense: PageDefenseEnum.AUTH,
		rolesAllowed: [RoleEnum.ADMIN],
	},
	{
		path: PathEnum.HOME_CONTENT,
		component: HomeContent,
		pageDefense: PageDefenseEnum.AUTH,
		rolesAllowed: [RoleEnum.ADMIN],
	},
];
