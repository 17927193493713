import { hmApi } from 'store/api';
import { getIsLogsEnabled, setIsLogsEnabled } from './endpoints';

export const endpoints = hmApi.injectEndpoints({
	endpoints: (builder) => ({
		getIsLogsEnabled: getIsLogsEnabled(builder),
		setIsLogsEnabled: setIsLogsEnabled(builder),
	}),
});

export const { useGetIsLogsEnabledQuery, useSetIsLogsEnabledMutation } =
	endpoints;
