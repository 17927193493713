import { PathEnum } from 'modules/common/enums';
import { SiderMenuType } from 'modules/common/types';

export const SiderMenuData: SiderMenuType = {
	DEFAULT_PATHS: PathEnum.CATEGORIES,
	GENERAL: [
		{
			name: 'users',
			path: PathEnum.USERS,
		},
		{
			name: 'upcomingCategories',
			path: PathEnum.UPCOMING_CATEGORIES,
		},
		{
			name: 'authors',
			path: PathEnum.AUTHORS,
		},
		{
			name: 'popups',
			path: PathEnum.USER_MESSAGES,
		},
		{
			name: 'configurations',
			path: PathEnum.CONFIGURATIONS,
		}
	],
	LANGUAGE_DEPENDENT: [
		{
			name: 'categories',
			path: PathEnum.CATEGORIES,
		},
		{
			name: 'items',
			path: PathEnum.SOUNDTRACKS,
		},
		{
			name: 'homeContent',
			path: PathEnum.HOME_CONTENT,
		},
	],
};
