import { PageDefenseEnum, PathEnum } from '../enums';
import { PageType } from '../types';
import { Configurations } from './Configurations';

export const pages: PageType[] = [
	{
		component: Configurations,
		path: PathEnum.CONFIGURATIONS,
		pageDefense: PageDefenseEnum.AUTH,
	},
];
