import { Card, Col, Row } from 'antd';
import { Switch } from 'modules/common/components';
import {
	useGetIsLogsEnabledQuery,
	useSetIsLogsEnabledMutation,
} from 'modules/logRocket/requests';
import { useTranslation } from 'react-i18next';

export const Configurations: React.FC = () => {
	const { t } = useTranslation('common', { keyPrefix: 'Configurations' });
	const [setIsLogsEnabled, setIsLogsEnabledMutation] =
		useSetIsLogsEnabledMutation();
	const isLoggingEnabledQuery = useGetIsLogsEnabledQuery();

	const isLogsEnabled = isLoggingEnabledQuery.data?.isEnabled;

	const onSwitchLogs = async (isEnabled: boolean) => {
		await setIsLogsEnabled(isEnabled);
		isLoggingEnabledQuery.refetch();
	};

	return (
		<Card>
			<Row>
				<Col span={24}>
					<h2>{t('configurations')}</h2>
				</Col>
				<Col span={24}>
					{t('logRocketLogs')}
					{': '}
					<Switch
						loading={
							isLoggingEnabledQuery.isFetching ||
							setIsLogsEnabledMutation.isLoading
						}
						value={isLogsEnabled}
						onClick={onSwitchLogs}
					/>
				</Col>
			</Row>
		</Card>
	);
};
